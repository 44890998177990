import React from 'react'
// Types
import { ModalSectionBaseProps, ModalSectionContentLogo } from '../../../../../types/modal-sections'
// Components
import { Box, Flex, Heading, Image, Text } from '../../../../../atoms'

export interface ContentLogoProps extends ModalSectionBaseProps {
  data: ModalSectionContentLogo
}

const ContentLogo: React.FC<ContentLogoProps> = ({ data }) => {
  return (
    <Box position={'relative'} height={'100%'} bg={'white'}>
      <Image
        draggable={false}
        alt={data.background.alt || ''}
        image={data.background.gatsbyImageData}
        style={{ width: '100%', height: '100%', position: 'absolute' }}
        imgStyle={{ objectFit: 'cover', objectPosition: 'top center' }}
      />
      <Box position={'relative'} zIndex={2} background={'transparent'} height={'100%'}>
        <Flex
          flexDirection={'column'}
          justifyContent={'center'}
          maxWidth={[600, 800]}
          px={[40, 40, 80]}
          mx={'auto'}
          height={'100%'}
        >
          {!!data.title && (
            <Heading as={'h4'} mb={5}>
              {data.title}
            </Heading>
          )}
          {!!data.content && <Text>{data.content}</Text>}
          {data.logos && data.logos.length > 0 && (
            <Flex mt={8} alignItems={'center'} flexWrap={'wrap'} mx={['-6px', '-8px']}>
              {data.logos.map((l) => {
                return (
                  <Box flex={['0 0 25%', `0 0 ${100 / 5}%`]} key={l.id} px={['6px', '8px']} my={2} opacity={0.48}>
                    <Image draggable={false} alt={l.asset.alt || l.name} image={l.asset.gatsbyImageData} />
                  </Box>
                )
              })}
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  )
}

export default ContentLogo
